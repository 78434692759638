.clip-card-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: black;
  border-radius: 5px;
}

.clip-card-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0px;
  outline: none;
  border-radius: 5px;
}


.clip-card-controls{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1px;
}

.clip-card-controls-duration-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.clip-card-controls-starttime-button {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 3;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}


.clip-card-controls-seleted-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 3;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}