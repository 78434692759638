.scrollable-list{
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  margin: auto;
}

.scrollable-list::-webkit-scrollbar{
  width: 0em;
}

.scrollable-loading-item{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
