.feed-info-title{
  padding: 10px;
  font-size: 20px;
  white-space: pre-line;
  margin: 0px;
}

.feed-info-content{
  padding: 10px;
  font-size: 15px;
  white-space: pre-line;
  margin: 0px;
}

.feed-info-poster{
  padding: 10px;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.feed-info-img{
  width:100%;
  max-width: 500px;
}

.feed-info-line{
  padding: 10px;
  font-size: 15px;
  white-space: pre-line;
  margin: 0px;
}