:root {
  --clr-font: #000000;
  --clr-base: white;
  --clr-primary:#000000;
  --clr-primary-lighter:#666666;
}

/* :root {
  --clr-font: #EEEEEE;
  --clr-base: #222831;
  --clr-primary:#393E46;
  --clr-primary-lighter:#00ADB5;
} */

html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}