.chapter-line{
  display: flex;
  align-items: baseline;
  gap: 10px; 
}

.chapter-text{
  font-size: 14px;
}

.chapter-time{
  font-size: 14px;
  font-family: 'Fira Mono', monospace;
}

.highlight {
  font-weight: bold;
}

.chapter-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chapter-empty-container::before {
  content: "";
  display: block;
  padding-top: 50%;
}


.chapter-empty-buntton {
  padding: 10px 20px;
  background-color: #007bff; /* 背景颜色 */
  color: #fff; /* 字体颜色 */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}