.embed-player-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: black;
}

.embed-player{
  position: absolute;  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: fill;
}
