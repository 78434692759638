.video-player-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: black;
 }

.video-player-vertical-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: black;
 }

.video-player {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
}

.video-controls{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1px;
}

.video-controls-time{
  position: absolute;
  bottom: 17px; /* 距离底部的距离 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 使元素在水平方向上居中 */
  color: #fff; /* 文字颜色 */
  font-size: 200%;
  font-family: Arial, sans-serif; /* 字体样式，可根据需要修改 */
  background-color: rgba(0, 0, 0, 0); /* 背景颜色，可以调整透明度 */
  padding: 0.5vw; /* 内边距 */
  border-radius: 0.5vw; /* 圆角 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 3;
}

.video-controls-play-pause-button {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.video-controls-volume-button {
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 3;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.video-controls-forward-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* 使元素在水平方向上居中 */
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  background: transparent;
  /* background: black; */
  border: none;
  cursor: pointer;
}

.video-controls-rwind-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* 使元素在水平方向上居中 */
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  background: transparent;
  border: none;
  cursor: pointer;
}

.video-controls-progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 17px; /* 调整进度条的高度 */
  background-color: transparent;
  outline: none;
  appearance: none; /* 隐藏默认样式 */
  z-index: 3;
}

.video-controls-progress-bar::-webkit-slider-thumb {
  /* position: absolute; */
  appearance: none;
  width: 15px; /* 调整滑块的宽度 */
  height: 15px; /* 调整滑块的高度 */
  background-color: white; /* 滑块颜色 */
  border-color: black;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
}

.video-controls-progress-bar::-moz-range-thumb {
  /* position: absolute; */
  width: 12px; /* 调整滑块的宽度 */
  height: 12px; /* 调整滑块的高度 */
  background-color: white; /* 滑块颜色 */
  border-color: black;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
  z-index: 3;
}


@media screen and (max-width: 1000px) {
  .video-player-container {
    padding-bottom: 90%;
  }
}