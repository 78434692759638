.feed-page-pannel-body{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-primary);
  box-sizing: border-box;
  background-color: var(--clr-base);
}

.feed-page-pannel-title{
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--clr-base);
  color: var(--clr-font);
}

.feed-page-pannel-media{
  width: 100%;
}

.feed-page-pannel-content{
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: var(--clr-base);
}
.feed-page-pannel-content-p{
  padding: 15px;
  white-space: pre-line;
}
.feed-page-pannel-buttom{
  margin-top: auto;
  width: 100%;
}
.feed-page-pannel-buttons{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.feed-page-pannel-buttons button:first-child {
  border-left: none;
}

.feed-page-pannel-buttons button:last-child {
  border-right: none;
}
.feed-page-pannel-buttons button{ 
  margin: 0;
  padding: 0;
  border: 1px solid black;
  color: black;
  outline: none;
  width: 100%;
  height: 42px;
}