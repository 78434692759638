


.view-set-contianer{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); /* 重复自动填充列，每列最小宽度为190px，平均占据可用空间 */
  gap: 2px; /* 列之间的间距（根据需要调整） */
  grid-row-gap: 2px;
}

.view-set-item{
  min-width: 110px; /* 设置每个标签项的最小宽度 */
  box-sizing: border-box; /* 包含内边距和边框在元素的总宽度和高度中 */
}


.view-empty-card-container{
  position: relative;
  width: 100%;
  background-color: grey;
  overflow: hidden;
}

.view-empty-card-container::before {
  content: "";
  display: block;
  padding-top: 125%;
}


.view-empty-card-button {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
  border: 0px;
  outline: none;
}