.feed-page{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.feed-page-list{
  height: 100%;
  width: 100%;
  max-width: 414px;
  flex-shrink: 0;
}

.feed-page-pannel{
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  background-color: var(--clr-base);
  color:var(--clr-font);
}

@media screen and (max-width: 1000px) {
  .feed-page-pannel {
    position: fixed;
    right: -100%;
    transition: right 0.3s ease-out;
  }

  .feed-page-pannel.active{
    right: 0;
  }
}






.feed-page-list-body{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-primary);
  box-sizing: border-box;
  background-color: var(--clr-base);
}

.feed-page-list-content{
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: var(--clr-base);
}


.feed-page-list-buttom{
  margin-top: auto;
  width: 100%;
}
.feed-page-list-buttons{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.feed-page-list-buttons button:first-child {
  border-left: none; /* 移除第一个按钮的右边框 */
}

.feed-page-list-buttons button:last-child {
  border-right: none; /* 移除最后一个按钮的左边框 */
}


.feed-page-list-buttons button{ 
  margin: 0;
  padding: 0;
  border: 1px solid black;
  color: black;
  outline: none;
  width: 100%;
  height: 42px;
}

