.view-card-container{
  position: relative;
  width: 100%;
  background-color: black;
  overflow: hidden;
}

.view-card-container::before {
  content: "";
  display: block;
  padding-top: 125%;
}

.view-card-player {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  box-sizing: border-box;
  border: 0px;
  outline: none;
}

.video-card-div{
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  z-index: 1px;
}

.video-card-div-title {
  position: absolute;
  color: white;
  bottom: 0;
}