.scollable-list-item{
  width: 100%;
  height: 80px;
  background-color: var(--clr-base);
  color:var(--clr-font);
  display: flex;
  align-items: center;
  gap:10px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid var(--clr-primary);
  user-select: none;
}

.scollable-list-item img{
  width: 50px;
  object-fit: cover;
  border-radius: 50%; 
}

.scollable-list-item dl {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  margin: 0;
}

.scollable-list-item dt {
  font-size: 10px;
  margin: 0;
}

.scollable-list-item dd{
  font-size: 12px;
  width: 100%;
  margin: 0px;
}

.scollable-list-item dd:last-child {
  font-size: 10px;
  margin-top: auto;
  display: flex;
  align-items: center;
}

.scollable-list-item dd:last-child i {
  margin-left: auto;
}